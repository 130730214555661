@if(showroomList().length){
<section class="more_to_explore" [ngClass]="
{'condition_1' : showroomList().length === 1, 'condition_2' : showroomList().length === 2, 'condition_3' : showroomList().length! >= 3}
">
  <div class="mx-1140 container">
    <div class="more_explore">
      <div class="cont_sec">
        <h2 class="heading_2 text-start">
          More to explore
        </h2>
        <p>Shop the curated spaces from our other Minoan {{showroomList().length === 1 ? 'property' : 'properties'}} </p>
      </div>
      @defer (on viewport){
      <div class="more_products product-carousel_wrapper">
        @if(showroomList().length > 2){
          <div class="right_sec arrow_btn">
            <button  [class.slick-disabled]="showroomList().length <= itemsPerView()" (click)="carousel.previousPage()" type="button" class="slick-prev slick-arrow" aria-disabled="true">
              <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.60531 0.826294L1.52637 6.90524L7.60531 12.9842" stroke="black" stroke-width="1.51974"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </button>
            <button [class.slick-disabled]="showroomList().length <= itemsPerView()" (click)="carousel.nextPage()" type="button" class="slick-next slick-arrow" aria-disabled="true">
              <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.39469 0.826294L7.47363 6.90524L1.39469 12.9842" stroke="#222222" stroke-width="1.51974"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </button>
          </div>
        }
        <app-carousel (slideChanged)="amplitudeService.showroomEntryEvents.morePropertiesShowroomCarouselArrowClick()" #carousel appWheelThrottle [isDiscoverSpace]="true"
                      (throttledWheel)="showroomList().length > itemsPerView() ? carouselScrollManually($event, carousel) : null" [items]="showroomListForCarousel()" [idKey]="'id'" [carouselConfig]="carouselConfig" [smallScreenConfig]="{loop: true, drag: false}">
           <ng-template #slide let-showroom let-index="index">
              <div class="product">
                <showroom-card [showroomDetail]="showroom"></showroom-card>
              </div>
            </ng-template>
        </app-carousel>
      </div>
      } @placeholder () {
          <p-skeleton height="100%" width="100%"></p-skeleton>
      }
    </div>
  </div>
</section>
}
