import {afterNextRender, AfterRenderPhase, Component, HostListener, Inject, NgZone, PLATFORM_ID} from '@angular/core';
import {CommonModule, isPlatformServer} from '@angular/common';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {AmplitudeService} from "./services/tracking/amplitude.service";
import {HomepageService} from "./services/homepage/homepage.service";
import {FooterService} from "./services/footer/footer.service";
import {FooterModule} from "./base/footer/footer.module";
import {HeaderModule} from "./base/header/header.module";
import {ShowroomsModule} from "./showrooms/showrooms.module";
import {environment} from "../environments/environment";
import {BookAStayCtaComponent} from "./shared/display-data/book-a-stay-cta/book-a-stay-cta.component";
import {ScreenService} from "./services/screen/screen.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderModule, FooterModule, ShowroomsModule, BookAStayCtaComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'minoan-showroom';

  isInnerPage: boolean = false;
  prevPos: number = 0;

  constructor(
    public router: Router,
    public amplitudeService: AmplitudeService,
    public homepageService: HomepageService,
    public footerService: FooterService,
    @Inject(PLATFORM_ID) public platformId: Object,
    public ngZone: NgZone,
    public screenService: ScreenService,
  ) {
    afterNextRender(async () => {
      window.onunload = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant"
        });
      }

      this.ngZone.runOutsideAngular(() => {
        this.homepageService.initAOS()
      })


      this.router.events.subscribe({
        next: (event) => {
          if(event instanceof NavigationEnd) {
            if(this.homepageService.shouldScroll) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant"
              })
              setTimeout(() => {
                this.footerService.isScrollingTopUpdated = false;
              }, 500);
            }
          }
        }
      })

      if(!environment.isPreview) {
        await this.amplitudeService.initializeAmplitude();
      }
    }, {
      phase: AfterRenderPhase.Write
    })

    if(isPlatformServer(this.platformId)) {
      this.homepageService.IS_IFRAME = environment.isPreview;
    } else {
      this.homepageService.IS_IFRAME = window.location !== window.parent.location
    }

    // For header / footer conditions
    this.router.events.subscribe({
      next: (event) => {
        if(event instanceof NavigationEnd) {
          const innerPagesList = ['/local-items', '/our-favorites', '/all-products']
          this.isInnerPage = innerPagesList.some((each) => event.urlAfterRedirects.includes(each));

          // Check if it is a space page
          if(!this.isInnerPage && this.homepageService.PROPERTY_DETAILS) {
            const allSpaceIds = this.homepageService.PROPERTY_DETAILS.spaceList.filter(each => each.subspaceName && each.productCount).map(each => each?.subspaceName!)!
            this.isInnerPage = allSpaceIds.some((each) => event.urlAfterRedirects.includes(each));
          }
        }
      }
    })

    // Start screen observer
    this.screenService.startObserver();


  }

  @HostListener('window: scroll', ['$event']) onScroll() {
    this.footerService.isScrollingTopUpdated = false;
    let currentPos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    if((currentPos - this.prevPos) < 0 ){
      this.prevPos = currentPos + 1;
      // console.log('scrolling top');
      this.footerService.isScrollingTopUpdated = true;
      this.footerService.isScrollingTop.next(true);
    }
    else{
      // console.log('scrolling bottom');
      this.prevPos = currentPos;
      this.footerService.isScrollingTopUpdated = true;
      this.footerService.isScrollingTop.next(false);
    }
  }

}
