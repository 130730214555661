import {ApplicationConfig} from '@angular/core';
import {PreloadAllModules, provideRouter, withPreloading} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration, withHttpTransferCacheOptions} from '@angular/platform-browser';
import {provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import {requestInterceptor} from "./interceptors/request/request.interceptor";
import {provideServerRendering} from "@angular/platform-server";
import {APP_BASE_HREF} from "@angular/common";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideClientHydration(withHttpTransferCacheOptions({includePostRequests: true})),
    provideHttpClient(withFetch(), withInterceptors([requestInterceptor])),
    provideServerRendering(),
    {
      provide: APP_BASE_HREF, useValue: '/shop/'
    }
  ]
};
